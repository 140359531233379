<template>
  <section>
    <b-overlay
      :show="isAPICallInProgress"
      rounded="sm"
    >
      <b-row class="mb-2">
        <b-col
          cols="8"
          offset-md="2"
        >
          <b-row class="pr-2 pl-2">
            <section id="faq-search-filter">
              <b-card
                no-body
                class="faq-search p-2"
              >
                <main>
                  <div class="cod-voucher">
                    <p>Voucher: {{ infoVoucher.couponToken }}</p>
                  </div>

                  <div
                    v-if="infoVoucher.expirationDate"
                    class="validate"
                  >
                    <p>Validade: {{ infoVoucher.expirationDate }}</p>
                  </div>

                  <div class="description">
                    <feather-icon
                      icon="GiftIcon"
                      size="25"
                      class="mr-1"
                      style="color: #FFF"
                    />
                    <h2>
                      <span v-if="infoVoucher.couponType === 'indicated'">{{ infoVoucher.voucherDescription }}</span>
                      <span v-else>
                        {{ infoVoucher.indicatorRewardMoneyFormat === 'fixed' ? `R$ ${infoVoucher.indicatorReward},00` : '' }}
                        {{ infoVoucher.indicatorRewardMoneyFormat === 'percent' ? `${infoVoucher.indicatorReward}% sobre o valor da venda` : '' }}
                        {{ infoVoucher.indicatorRewardMoneyFormat === '' ? infoVoucher.indicatorReward : '' }}
                      </span>
                    </h2>
                  </div>

                  <div class="status">
                    <p>{{ infoVoucher.currentStatus }}</p>
                    <p> Este cliente é: {{ infoVoucher.couponType === 'indicated' ? 'Indicado' : 'Parceiro Indicador' }}</p>
                    <p><b>{{ infoVoucher.currentStageDescription }}</b></p>
                  </div>

                </main>
              </b-card>
            </section>
          </b-row>

          <b-row class="pr-2 pl-2">
            <b-col cols="6">
              <div class="d-flex mb-2">
                <feather-icon
                  icon="CircleIcon"
                  size="15"
                  class="mr-1"
                />
                <p><strong>Nome do indicado</strong> <br>{{ infoVoucher.indicatedName }}</p>
              </div>

              <div class="d-flex mb-2">
                <feather-icon
                  icon="CircleIcon"
                  size="15"
                  class="mr-1"
                />
                <p><strong>Email do indicado</strong> <br> {{ infoVoucher.indicatedEmail }}</p>
              </div>
              <div class="d-flex mb-2">
                <feather-icon
                  icon="CircleIcon"
                  size="15"
                  class="mr-1"
                />
                <p><strong>Telefone do indicado</strong> <br> {{ infoVoucher.indicatedPhone }}</p>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="d-flex mb-2">
                <feather-icon
                  icon="CircleIcon"
                  size="15"
                  class="mr-1"
                />
                <p><strong>Nome do Indicador</strong> <br>{{ infoVoucher.indicatorName }}</p>
              </div>
              <div class="d-flex mb-2">
                <feather-icon
                  icon="CircleIcon"
                  size="15"
                  class="mr-1"
                />
                <p><strong>Recompensa do Indicador</strong> <br>
                  {{ infoVoucher.indicatorRewardMoneyFormat === 'fixed' ? `R$ ${infoVoucher.indicatorReward},00` : '' }}
                  {{ infoVoucher.indicatorRewardMoneyFormat === 'percent' ? `${infoVoucher.indicatorReward}% sobre o valor da venda` : '' }}
                  {{ infoVoucher.indicatorRewardMoneyFormat === '' ? infoVoucher.indicatorReward : '' }}
                </p>
              </div>
            </b-col>
            <b-col class="mt-2">
              <b-button
                v-if="infoVoucher.couponType === 'indicated'"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="cancelateVoucher(infoVoucher.couponToken)"
              >
                Cancelar indicação
                <feather-icon
                  icon="ArrowRightIcon"
                  size="15"
                  class="ml-1"
                />
              </b-button>
              <b-button
                v-if="infoVoucher.currentStage !== 'Cancelado'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.dar-baixa
                variant="primary"
                class="ml-1"
              >
                Dar baixa
                <feather-icon
                  icon="ArrowRightIcon"
                  size="15"
                  class="ml-1"
                />
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="$router.back()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="15"
                  class="mr-1"
                />
                Voltar
              </b-button>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-overlay>

    <!-- modal dar baixa-->
    <b-modal
      id="dar-baixa"
      ref="dar-baixa"
      title="Dar baixa"
      ok-title="Confirmar"
      cancel-title="Voltar"
      size="lg"
      centered
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkDarBaixa"
    >
      <p> Ao clicar em confirmar, a indicação será baixada e a recompensa liberada ao indicador, após a confirmação não será possível efetuar o estorno da recompensa, deseja continuar?
      </p>
      <form
        ref="formDarBaixa"
        @submit.stop.prevent="handleSubmitDarBaixa"
      >
        <b-form-group
          v-if="infoVoucher.couponType === 'indicated'"
          :state="priceState"
          label="Valor total da compra"
          label-for="price-input"
          invalid-feedback="O valor da compra é obrigatório"
        >
          <b-input-group
            prepend="R$"
          >
            <b-form-input
              id="price-input"
              v-model="price"
              :state="priceState"
              class="form-control"
              placeholder="25,00"
              required
            />
          </b-input-group>
        </b-form-group>
      </form>
    </b-modal>

    <!-- modal cancelar indicação-->
    <b-modal
      id="cancelar-indicacao"
      ref="cancelar-indicacao"
      title="Cancelar Indicação"
      ok-title="Confirmar"
      cancel-title="Voltar"
      size="lg"
      centered
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkCancellation"
    >
      <p> Ao confirmar o cancelamento, o indicador será notificado no painel de indicações sobre o cancelamento dessa indicação.
      </p>
      <form
        ref="cancellation"
        @submit.stop.prevent="handleSubmitCancellation"
      >
        <b-form-group
          :state="reasonCancellationState"
          label="Informe abaixo o motivo do cancelamento da indicação"
          label-for="reason-input"
          invalid-feedback="O motivo do cancelamento é obrigatório"
        >
          <b-form-input
            id="reason-input"
            v-model="reasonCancellation"
            :state="reasonCancellationState"
            class="form-control"
            placeholder="Digite aqui"
            required
          />
        </b-form-group>
      </form>
    </b-modal>

    <!--modal erro-->
    <b-modal
      v-model="showModalErro"
      ok-title="Voltar"
      centered
      ok-only
    >
      <div class="modal-erros">
        <feather-icon
          icon="AlertTriangleIcon"
          size="60"
          class="text-danger"
        />
        <h2>Erro Inesperado</h2>
        <p>
          Lamentamos o ocorrido. Tente novamente.
        </p>
      </div>
    </b-modal>

  </section>

</template>

<script>

// import { api } from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'

import {
  BRow, BCol, BCard, BButton, BModal, VBModal, BFormGroup, BInputGroup, BFormInput, BOverlay,
} from 'bootstrap-vue'

import { localize } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      price: '',
      priceState: null,
      reasonCancellation: '',
      reasonCancellationState: null,
      infoVoucher: {},
      showModalErro: false,
    }
  },
  mounted() {
    localize('pt_BR')

    const queryParam = this.$route.query.infoVoucher
    const describe = JSON.parse(atob(queryParam))
    this.infoVoucher = describe
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  methods: {
    resetModal() {
      this.price = ''
      this.priceState = null
      this.reasonCancellation = ''
      this.reasonCancellationState = null
    },
    // funções para o modal de dar baixa
    checkFormValidityDarBaixa() {
      const valid = this.$refs.formDarBaixa.checkValidity()
      this.priceState = valid
      return valid
    },
    handleOkDarBaixa(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitDarBaixa()
    },
    handleSubmitDarBaixa() {
      if (!this.checkFormValidityDarBaixa()) {
        return
      }
      // console.log('price: ', this.price.replace(/,/g, '.'))

      useJwt.checkoutCoupon({
        couponToken: this.infoVoucher.couponToken,
        purchaseAmount: this.infoVoucher.couponType === 'indicated' ? parseFloat(this.price.replace(/,/g, '.')) : parseFloat('0'),
      })
        .then(response => {
          this.$swal({
            title: 'Sucesso!',
            text: 'Sua indicação foi concluída com sucesso.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push({
                name: 'apps-indicates',
              })
            }
          })
          return response
        })
        .catch(error => {
          this.showModalErro = true
          return error
        })

      this.$nextTick(() => {
        this.$refs['dar-baixa'].toggle('#toggle-btn')
      })
    },
    // funções para o modal de cancelamento
    checkFormValidityCancellation() {
      const valid = this.$refs.cancellation.checkValidity()
      this.reasonCancellationState = valid
      return valid
    },
    //
    cancelateVoucher(couponToken) {
      this.$swal({
        title: 'Tem certeza que deseja cancelar este cupom?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar',
        cancelButtonText: 'Voltar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.handleCancelateVoucher(couponToken)

          this.$swal({
            icon: 'success',
            title: 'Cancelado!',
            text: 'Seu cupom foi cancelado com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    handleCancelateVoucher(couponToken) {
      // api.put(`/coupon/cancelate/${couponToken}`)
      useJwt.cancelateCoupon(couponToken)
        .then(response => {
          this.$router.push({
            name: 'apps-indicates',
          })
          return response
        }).catch(error => error)
    },
    //
    handleOkCancellation(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitCancellation()
    },
    handleSubmitCancellation() {
      if (!this.checkFormValidityCancellation()) {
        return
      }
      // console.log('o motivo do cancelamento foi', this.reasonCancellation)
      this.$nextTick(() => {
        this.$refs['cancelar-indicacao'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-faq.scss';

    #faq-search-filter{
        display: flex;
        width: 100%;

        .faq-search {
            display: flex;
            width: 100%;
            background-color: #7367F0 !important;
        }

        main{
            display: grid;
            grid-template-columns: 2;

            grid-template-areas:"cod val"
                                "desc desc"
                                "stt stt";

            .cod-voucher { grid-area: cod; }
            .validate { grid-area: val; text-align: right; }
            .description { grid-area: desc; text-align: center; display: flex; align-items: center; justify-content: center;}
            .status { grid-area: stt; text-align: center;}

            p {
              color: #FFF;
              font-size: 1.25rem;
              font-weight: 400;
            }

            h2 {
              color: #FFF;
              font-size: 2rem;
              font-weight: 600;
              margin: 1rem 0;
            }
        }
    }

    .form-validate {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15rem;

        .form-group {
            flex: 1;
            margin-right: 1rem;
        }
    }

    .modal-erros{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2{
        margin: 1rem 0;
        font-size: 1.4rem;
      }
    }
</style>
